import React, { useRef } from "react"
import Alert from "../components/alert"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import IframeResizer from "iframe-resizer-react"

export default function Test({ pathContext: { src, title, alert } }) {
  const form = alert ? alert.form : null
  const iframeRef = useRef(null)

  return (
    <Layout hideAlert={true} header={false}>
      <Helmet
        title={`${title} | Kroger Precision Marketing`}
        bodyAttributes={{
          class: "stretched sticky-footer",
        }}
        meta={[
          {
            name: `robots`,
            content: `noindex, nofollow`,
          },
        ]}
      />
      <SEO title={title} keywords={[`gatsby`, `application`, `react`]} />
      <section>
        <div className="content-wrap">
          <IframeResizer
            log={false}
            forwardRef={iframeRef}
            src={src}
            style={{ minWidth: "100%", height: "500px" }}
          />
        </div>
      </section>
      {form && (
        <Alert
          theme={alert.theme}
          title={form.header}
          src={form.url}
          openLabel={form.openLabel}
        />
      )}
    </Layout>
  )
}
